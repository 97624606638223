export const Buttons = 'Buttons allow users to take actions, and make choices, with a single tap.'
export const ButtonGroup = 'The ButtonGroup component can be used to group related buttons.'
export const Checkboxes = 'Checkboxes allow the user to select one or more items from a set.'
export const floatingaActionAutton = 'A floating action button (FAB) performs the primary, or most common, action on a screen.'
export const RadioButtons = 'Radio buttons allow the user to select one option from a set.'
export const Ratings = 'Ratings provide insight regarding others opinions and experiences, and can allow the user to submit a rating of their own.'
export const Select = 'Select components are used for collecting user provided information from a list of options.'
export const Sliders = 'Sliders allow users to make selections from a range of values.'
export const Switches = 'Switches toggle the state of a single setting on or off.'
export const TextFields = 'Text fields let users enter and edit text.'
export const Toggle = 'Toggle buttons can be used to group related options.'
export const Avatars = 'Avatars are found throughout material design with uses in everything from tables to dialog menus.'
export const Tables = 'Tables display information in a way thats easy to scan, so that users can look for patterns and insights. They can be embedded in primary content, such as cards. They can include'
export const Tooltips = 'Tooltips display informative text when users hover over, focus on, or tap an element.'
export const List = "Lists are continuous, vertical indexes of text or images."
export const LogoAlt = 'Logo'
export const WelcomeTitle = 'DSM CultureAI App'
export const WelcomeText = `Please log in to continue.`