/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Auth } from "aws-amplify";

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: "eu-west-1",
  aws_cognito_identity_pool_id:
    "eu-west-1:c648fa2e-82eb-45bb-be44-5e1c0bfb1ff1",

  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_C3D4qhXAB",
  aws_user_pools_web_client_id: "3j4v24h9ta9jhoj7hcfgbbcg89",
  oauth: {
    domain: "auth.crp-dt-biotech.prj.analytics.dsm.app",
    scope: ["email", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://cultureai.crp-dt-biotech.prj.analytics.dsm.app/",
    redirectSignOut: "https://cultureai.crp-dt-biotech.prj.analytics.dsm.app/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

// DEV SETUP

// const awsmobile = {
//   aws_project_region: "eu-west-1",
//   aws_cognito_identity_pool_id:
//     "eu-west-1:c648fa2e-82eb-45bb-be44-5e1c0bfb1ff1",
//   aws_cognito_region: "eu-west-1",
//   aws_user_pools_id: "eu-west-1_C3D4qhXAB",
//   aws_user_pools_web_client_id: "3j4v24h9ta9jhoj7hcfgbbcg89",
//   oauth: {
//     domain: "auth.crp-dt-biotech.prj.analytics.dsm.app",
//     scope: ["email", "openid", "aws.cognito.signin.user.admin"],
//     redirectSignIn: "http://localhost:3000/",
//     redirectSignOut: "http://localhost:3000/",
//     responseType: "code",
//   },
//   federationTarget: "COGNITO_USER_POOLS",
// };

export default awsmobile;
