import React from "react";
// import { Logo } from '../utils/ImageString'
import { WelcomeText, WelcomeTitle } from "../utils/string";
import { Typography } from "@mui/material";

function Welcome() {
  return (
    <>
      <Typography variant="p" align="center">{WelcomeText}</Typography>
      <br />
    </>
  );
}

export default Welcome;
