import React from "react";
import Gaussian from "./Gaussian";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Paper } from "@mui/material";

function Prediction({ name, mu, vari }) {
  return (
    <Paper
      sx={{
        my: 1,
        mx: "auto",
        p: 2,
      }}
    >
      <Grid container wrap="nowrap" spacing={0}>
        <Grid item xs={4}>
          <Typography variant="h6" align="left">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Gaussian mu={mu} sigma={vari} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Prediction;

{
  /* <div className="row g-3 align-items-center p-1">
      <div className="col-4">
        <h5>
          {name}
        </h5>
      </div>
      <div className="col-6">
        <Gaussian mu={mu} sigma={vari}/>
      </div>
    </div> */
}
