import React from "react";
import RecipeIngredient from "./RecipeIngredient";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { ButtonGroup } from "@mui/material";

const RecipeIngredientList = ({
  title,
  recipe_ingredients,
  onDelete,
  onChange,
  onPredict,
}) => {
  if (recipe_ingredients.length === 0) {
    return (
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 1 }}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <div>Please add some ingredients.</div>
      </Box>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 1 }}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        {recipe_ingredients.map((recipe_ingredient) => (
          <RecipeIngredient
            key={recipe_ingredient.id}
            recipe_ingredient={recipe_ingredient}
            onDelete={() => onDelete(recipe_ingredient)}
            onChange={(value) => onChange(recipe_ingredient, value)}
          />
        ))}
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical contained button group"
        variant="contained"
      >
        <Button key="one" onClick={onPredict}>Predict</Button>
      </ButtonGroup>
      </Box>
    );
  }
};

export default RecipeIngredientList;
