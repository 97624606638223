import {
  ComposedChart,
  ReferenceLine,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let data = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <p className="label">{`${data.label} = ${data.x}`}</p>
      </div>
    );
  }
  return null;
};

function makeArr(startValue, stopValue, cardinality) {
  var arr = [];
  var step = (stopValue - startValue) / (cardinality - 1);
  for (var i = 0; i < cardinality; i++) {
    arr.push(startValue + step * i);
  }
  return arr;
}

function normalPdf(x, mu, sigma) {
  return (
    (1 / (sigma * Math.sqrt(2 * Math.PI))) *
    Math.exp(-0.5 * ((x - mu) / sigma) ** 2)
  );
}

const Gaussian = ({ mu, sigma }) => {
  if (sigma === 0) sigma = 0.01
  const mu0 = mu.toFixed(2);
  var x = makeArr(mu - 4 * sigma, mu + 4 * sigma, 9);
  x = x.map(e => {return e.toFixed(2);});

  const labels = [
    "\u03BC - 4 \u03C3",
    "\u03BC - 3 \u03C3",
    "\u03BC - 2 \u03C3",
    "\u03BC - \u03C3",
    "\u03BC",
    "\u03BC + \u03C3",
    "\u03BC + 2 \u03C3",
    "\u03BC + 3 \u03C3",
    "\u03BC + 4 \u03C3",
  ];
  const y_vals = x.map((e) => {
    return normalPdf(e, mu, sigma);
  });

  const data = x.map((e, i) => {
    return { x: e, y: y_vals[i], label: labels[i] };
  });

  return (
    <ComposedChart width={200} height={100} data={data}>
      <Area type="monotone" dataKey="y" stroke="#8884d8" />
      {/* <CartesianGrid stroke="#ccc" strokeDasharray="3 3" /> */}
      <XAxis dataKey="x" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <ReferenceLine x={mu0} stroke="green" label={`\u03BC = ${mu0}`} />
    </ComposedChart>
  );
};

export default Gaussian;
