import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Logo } from "../../utils/ImageString";
import { LogoAlt } from "../../utils/string";
import { styles } from "./FooterStyle";

function Footer(props) {
  const { classes } = props;

  return (
    <footer className={`footer`}>
      <Paper className={classes.root} elevation={1}>
        <div className="footer-logo">
          <img src={Logo} alt={LogoAlt} title={LogoAlt} />{" "}
        </div>{" "}
        <div className="footer-text">
          <Typography variant="body2">
            Health • Nutrition • Bioscience{" "}
          </Typography>{" "}
        </div>{" "}
      </Paper>{" "}
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
