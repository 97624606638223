import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Item } from "./MaterialUiComponents/IndexStyle";
import { Paper } from "@mui/material";
import { TextField } from "@mui/material";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

const RecipeIngredient = ({ recipe_ingredient, onDelete, onChange }) => {

  const [amount, setAmount] = useState(0.5);

  const handleChange = (e) => {
    onChange(e.target.value)
    setAmount(e.target.value)
  };

  return (
    <Paper
      sx={{
        my: 1,
        mx: "auto",
        p: 2,
      }}
    >
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs={5}>
          <Typography variant="h6" align="left">
            {recipe_ingredient.name}
          </Typography>
        </Grid>
        <Grid item xs={5}>
        <TextField
            id="outlined-number"
            value={amount}
            onChange={handleChange}
            label="Amount"
            type="number"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0,
              max: 2,
              step: 0.01
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Fab color="primary" aria-label="add" size="small">
            <DeleteSharpIcon onClick={onDelete}/>
          </Fab>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RecipeIngredient;
