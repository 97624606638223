import React from 'react'

function NotFound() {
    return ( <
        div >
        Page not found <
        /div>
    )
}

export default NotFound