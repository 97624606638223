import React from "react";
import Prediction from "./Prediction";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

function PredictionList({ predictions, title }) {
  console.log("PredictionList", predictions);
  if (predictions.length === 0) {
    return (
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 1 }}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        Please create a recipe and call predict.
      </Box>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 1 }}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        {predictions.map((pred) => (
          <Prediction
            key={pred.id} 
            name={pred.name}
            mu={pred.mu}
            vari={pred.var}
          />
        ))}
      </Box>
    );
  }
}

export default PredictionList;

// if (predictions.length === 0) {
//   return (
//     <div className="list-group">
//       <h4>{title}</h4>
//       <div>Please create a recipe and call predict.</div>
//     </div>
//   );
// } else {
//   return (
//     <div className="list-group">
//       <h4>{title}</h4>
//       {predictions.map((pred) => (
//         <Prediction
//           key={pred.id}
//           name={pred.name}
//           mu={pred.mu}
//           vari={pred.var}
//         />
//       ))}
//     </div>
//   );
// }
