import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Item } from "./MaterialUiComponents/IndexStyle";
import { Paper } from "@mui/material";

const Ingredient = ({ ingredient, onAdd }) => {
  return (
    <Paper
      sx={{
        my: 1,
        mx: "auto",
        p: 2,
      }}
    >
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h6" align="left">
            {ingredient.name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Fab color="primary" aria-label="add" size="small">
            <AddIcon onClick={onAdd} />
          </Fab>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Ingredient;
