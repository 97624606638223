import React from "react";
import { Typography } from "@mui/material";
import { Link } from "@mui/material";

function About() {
  return (
    <>
      <Typography variant="h2">About</Typography>
      <Typography variant="p">
        Link to confluence click{" "}
        <Link href="https://dsmplatform.atlassian.net/wiki/spaces/SDLDS/overview">
          here
        </Link>.
      </Typography>
    </>
  );
}

export default About;
