import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Item } from "./IndexStyle";

import IngredientList from "../IngredientList";
import RecipeIngredientList from "../RecipeIngredientList";
import PredictionList from "../PredictionList";
import { UserContext } from "../../context/AuthContext";
import { useContext } from "react";
import { useState, useEffect } from "react";
import yoghurt from "../../yoghurt-image2.jpg";
import { Auth } from "aws-amplify";

function MaterialUiComponents() {
  const userDetails = useContext(UserContext);
  console.log(userDetails);
  const sortIngredients = (ing_a, ing_b) => {
    return parseFloat(ing_a.id) - parseFloat(ing_b.id);
  };

  const [ingredients, setIngredients] = useState([]);
  const [recipe_ingredients, setRecipeIngredients] = useState([]);
  const [predictions, setPredictions] = useState([]);

  const getToken = async () => {
    return userDetails.signInUserSession.accessToken.jwtToken;
  };

  useEffect(() => {
    const getIngredients = async () => {
      const fetched_ingredients = await fetchIngredients();
      setIngredients(fetched_ingredients);
    };

    getIngredients();
  }, []);

  const addRecipeIngredient = (ingredient) => {
    console.log("Add RecipeIngredient", ingredient);

    // Remove from ingredients
    setIngredients(
      ingredients
        .filter((ing) => ing.id !== ingredient.id)
        .sort(sortIngredients)
    );

    // Add to recipe_ingredients
    const recipe_ingredient = ingredient;
    recipe_ingredient["amount"] = 0.5;

    setRecipeIngredients(
      [...recipe_ingredients, recipe_ingredient].sort(sortIngredients)
    );
  };

  const deleteRecipeIngredient = (recipe_ingredient) => {
    console.log("Delete RecipeIngredient", recipe_ingredient);

    setRecipeIngredients(
      recipe_ingredients
        .filter((rec_ing) => rec_ing.id !== recipe_ingredient.id)
        .sort(sortIngredients)
    );

    delete recipe_ingredient["amount"];

    setIngredients([...ingredients, recipe_ingredient].sort(sortIngredients));
  };

  const fetchIngredients = async () => {
    // This should be replaced by an API call with useEffect()
    const ingredients = [
      {
        id: 1,
        name: "500-F",
      },
      {
        id: 2,
        name: "100-F",
      },
      {
        id: 3,
        name: "400-E",
      },
      {
        id: 4,
        name: "200-B",
      },
      {
        id: 5,
        name: "100-R",
      },
      {
        id: 6,
        name: "300-R",
      },
      {
        id: 7,
        name: "200-C",
      },
      {
        id: 8,
        name: "400-C",
      },
    ];

    return ingredients;
  };

  const fetchJson = async (url, method, body) => {
    const headers = {};
    const token = await getToken();
    console.log("token", token);
    if (token !== undefined) {
      headers["Authorization"] = `Bearer ${token}`;
      // headers["Authorization"] = `Bearer eyJraWQiOiJhY3RldlBGczdYbm5OdU9XeW0zSUFQZ29Ld1FqVSt5Z3FyZFdpUmZcL1d1az0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmNjQ0N2E3NC1jNjAzLTQxNWQtYTVlNy00MTJkNzllMjVmN2MiLCJjb2duaXRvOmdyb3VwcyI6WyJldS13ZXN0LTFfQzNENHFoWEFCX0RTTS1BenVyZS1BRCJdLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBlbWFpbCIsImF1dGhfdGltZSI6MTY0OTY5MjY1NSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfQzNENHFoWEFCIiwiZXhwIjoxNjQ5Njk2MjU1LCJpYXQiOjE2NDk2OTI2NTUsInZlcnNpb24iOjIsImp0aSI6IjJlMGU5NTEyLTkzOGQtNDZjNy04ZDU3LTJkZmQyNzNjM2Q4MiIsImNsaWVudF9pZCI6IjNqNHYyNGg5dGE5amhvajdoY2ZnYmJjZzg5IiwidXNlcm5hbWUiOiJEU00tQXp1cmUtQURfS2ZiMjhSZkx3REY1a3J1VEdGVXFuQmhxQ2c1cmhWOVNmZVNZakFBcUFYayJ9.dmpvW-N-uKQ_zNAFjEUKR49Xfb3NUAzS_XITy5TdJSOwdKhMVxokKp7IreDaR1dfAF96Xv6VdtX3qJ9IgDmi44Me5fCqADDjPaFTRRO_w4c9IfmfhdDSAvH451HPCevvk-xh0xQeK1iCc4LGdhV-IRGusJv5MlyPl3in9zvLp98EzQXHeUkgV3MNbInrot8zhez-L4GP8pQBoYVW52tUKCGw1O4l_OoMk3q-R3VT4FkMEYYtSGeUjBe1JZOkUaixh-1ksDmgb07Gu8lDfXKsWyw4ylKsXpHVp80O-7_AKBQ6yLu7R38XFUUoKX9p4Pq2mhsZKy_WTii9KeyyxedRFw`
    }
    if (method === "POST") {
      headers["Content-Type"] = "application/json";
    }
    const init = {
      method: method,
      headers: new Headers(headers),
      mode: "cors",
    };
    if (body !== undefined) {
      init.body = body;
    }
    console.log("headers", headers);
    const response = fetch(url, init).then((response) => {
      return response.json();
    });
    return response;
  };

  const fetchPredictions = async (payload) => {
    console.log("fetchPredictions");
    const body = JSON.stringify({ ingredients: payload });

    const res = await fetchJson(
      "https://pcxn4oypm8.execute-api.eu-west-1.amazonaws.com/prod/predict",
      // "https://ilc5mumt47.execute-api.eu-west-1.amazonaws.com/dev/predict",
      "POST",
      body
    );
    return res;
  };

  const onPredict = async (e) => {
    console.log("onPredict");
    e.preventDefault();

    if (recipe_ingredients.length === 0) {
      alert("Please add ingredients to the recipe first.");
    }

    const payload = JSON.parse(JSON.stringify(recipe_ingredients));

    payload.forEach((rec_ing) => {
      rec_ing["amount"] = Number(rec_ing["amount"]);
      console.log(rec_ing);
      delete rec_ing["id"];
    });

    console.log("payload", payload);

    const resp_data = await fetchPredictions(payload);

    console.log("resp_data", resp_data);

    // TODO: Validate if the response from backend was as expected.
    resp_data.forEach((item, i) => {
      item["id"] = i + 1;
    });

    setPredictions(resp_data);
  };

  const onRecipeValueChange = (recipe_ingredient, value) => {
    const recipeIndex = recipe_ingredients.findIndex(
      (obj) => obj.id === recipe_ingredient.id
    );
    const new_recipe_ingredients = recipe_ingredients;
    new_recipe_ingredients[recipeIndex].amount = value;

    setRecipeIngredients(new_recipe_ingredients);
  };
  return (
    <>
      <Box>
        <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h2" align="center">
                CultureAI: Recipe Builder
              </Typography>
              <Typography component="p" align="center">
                Welcome! CultureAI is a recipe builder for yoghurt cultures.
                With this tool you can make predictions about the features of
                yoghurt given certain strains and their abundancy in the
                culture.
              </Typography>
              <Divider />
              <a href={"/"}>
                <img
                  src={yoghurt}
                  width="400"
                  alt="Image"
                  title="Yoghurt"
                  href={"/"}
                />{" "}
              </a>
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <IngredientList
                ingredients={ingredients}
                title="Add Ingredient"
                onAdd={addRecipeIngredient}
              />
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <RecipeIngredientList
                recipe_ingredients={recipe_ingredients}
                title="Recipe Ingredients"
                onDelete={deleteRecipeIngredient}
                onChange={onRecipeValueChange}
                onPredict={onPredict}
              />
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <PredictionList predictions={predictions} title={"Predictions"} />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default MaterialUiComponents;
