import LayoutComponent from "../components/LayoutComponents/LayoutComponent";
import {
  ThemeProvider,
  createTheme
} from "@mui/material/styles";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Footer from "../components/FooterComponent/Footer";
import MaterialUiComponents from "../components/MaterialUiComponents";
import About from "./About";
import NotFound from "./NotFound";

// main homepage loading layout

// you can coustmize all component theme from here
const theme = createTheme({
  palette: {
    primary: {
      main: "#0099f4",
    },
  },
  typography: {
    fontFamily: ["Fira Sans"].join(","),
  },
});

function Home() {
  return (
    <div className="home" >
      <ThemeProvider theme={theme} >
        <BrowserRouter >
          <Routes >
            <Route path="/" element={< LayoutComponent />} >
              <Route index element={< MaterialUiComponents />} />
              <Route path="about"
                element={< About />
                }
              />
              <Route path="*"
                element={< NotFound />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default Home;
