import { Grid } from "@mui/material";
import React from "react";
import Ingredient from "./Ingredient";
import { Item } from "./MaterialUiComponents/IndexStyle";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const IngredientList = ({ title, ingredients, onAdd }) => {
  if (ingredients.length === 0) {
    return (
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 1 }}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <div>No more ingredients to add.</div>
      </Box>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 1 }}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        {ingredients.map((ingredient) => (
          <Ingredient
            key={ingredient.id}
            ingredient={ingredient}
            onAdd={() => onAdd(ingredient)}
          />
        ))}
      </Box>
    );
  }
};

export default IngredientList;
